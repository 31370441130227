import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class StepSuccessPocketViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-success-pocket';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  user_has_balance = false;

  initialize = (user_has_balance: boolean) => {
    this.user_has_balance = user_has_balance;
  }

  goToDashboard = () => {
    if (this.user_has_balance) {
      window.location.href = '/sowos-pocket';
    } else {
      window.location.href = '/';
    }
  }

  goToDashboardGoals = () => {
    window.location.href = '/goals-dashboard';
  }
}
