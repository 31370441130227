


















































import { Component, PropSync, Vue } from 'vue-property-decorator';

import StepSuccessPocketViewModel from '@/vue-app/view-models/components/flagship/flagship-link-goals/steps-pocket/step-success-pocket-view-model';

@Component({ name: 'StepSuccessPocket' })
export default class StepSuccessPocket extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('userHasBalance', { type: Boolean, required: true })
  user_has_balance!: boolean;

  step_success_pocket_model = Vue.observable(
    new StepSuccessPocketViewModel(),
  );

  mounted() {
    this.step_success_pocket_model.initialize(this.user_has_balance);
  }
}
